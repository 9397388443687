var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        id: "edit_material_modal",
        centered: "",
        title: _vm.$t(_vm.action) + _vm.$t("material"),
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        backdrop: false,
        "no-close-on-backdrop": "",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "text-center",
        },
        [
          _c(
            "div",
            {
              staticClass: "col-xs-12",
            },
            [
              _c(
                "div",
                {
                  staticClass: "col-xs-12",
                },
                [
                  _c("input", {
                    staticClass: "btn btn-primary",
                    attrs: {
                      id: "loadFileXml",
                      type: "button",
                      value: _vm.$t("upload"),
                    },
                  }),
                  _c("input", {
                    staticStyle: {
                      display: "none",
                    },
                    attrs: {
                      id: "material_edit_file",
                      type: "file",
                      name: "material_file",
                      accept:
                        ".doc,.docx,.pdf,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    },
                  }),
                  _c("div", [_vm._v(_vm._s(this.file))]),
                  _c("div", [_vm._v(_vm._s(_vm.material.title))]),
                ]
              ),
              _c(
                "div",
                {
                  attrs: {
                    slot: "modal-header",
                  },
                  slot: "modal-header",
                },
                [
                  _c(
                    "h2",
                    {
                      staticStyle: {
                        color: "blue",
                        "text-align": "center",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t(_vm.action) + _vm.$t("material")))]
                  ),
                ]
              ),
              _c(
                "label",
                {
                  staticClass: "col-xs-3 mt-2",
                },
                [_vm._v(_vm._s(_vm.$t("material") + _vm.$t("name")))]
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-9",
                },
                [
                  _c("b-form-input", {
                    staticClass: "col-xs-12",
                    attrs: {
                      label: _vm.$t("material") + _vm.$t("name"),
                    },
                    model: {
                      value: _vm.material.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.material, "title", $$v)
                      },
                      expression: "material.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "label",
                {
                  staticClass: "col-xs-3 mt-3",
                },
                [_vm._v(_vm._s(_vm.$t("material") + _vm.$t("type")))]
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-9",
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.types,
                    },
                    model: {
                      value: _vm.material.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.material, "type", $$v)
                      },
                      expression: "material.type",
                    },
                  }),
                ],
                1
              ),
              _c(
                "label",
                {
                  staticClass: "col-xs-3 mt-3",
                },
                [_vm._v(_vm._s(_vm.$t("select") + _vm.$t("level")))]
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-9",
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.level,
                    },
                    model: {
                      value: _vm.material.level,
                      callback: function ($$v) {
                        _vm.$set(_vm.material, "level", $$v)
                      },
                      expression: "material.level",
                    },
                  }),
                ],
                1
              ),
              _c(
                "label",
                {
                  staticClass: "col-xs-3 mt-3",
                },
                [_vm._v(_vm._s(_vm.$t("select") + _vm.$t("category")))]
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-9",
                },
                [
                  _c("b-form-select", {
                    staticStyle: {
                      width: "100%",
                    },
                    attrs: {
                      options: _vm.category,
                      "options-value": "val",
                      placeholder: _vm.$t("search_category_here"),
                      multiple: "",
                    },
                    model: {
                      value: _vm.material.category,
                      callback: function ($$v) {
                        _vm.$set(_vm.material, "category", $$v)
                      },
                      expression: "material.category",
                    },
                  }),
                ],
                1
              ),
              _c(
                "label",
                {
                  staticClass: "col-xs-3 mt-3",
                },
                [_vm._v(_vm._s(_vm.$t("select") + _vm.$t("theme")))]
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-9",
                },
                [
                  _c("b-form-select", {
                    staticStyle: {
                      width: "100%",
                    },
                    attrs: {
                      options: _vm.theme,
                      "options-value": "val",
                      placeholder: _vm.$t("search_theme_here"),
                      multiple: "",
                    },
                    model: {
                      value: _vm.material.theme,
                      callback: function ($$v) {
                        _vm.$set(_vm.material, "theme", $$v)
                      },
                      expression: "material.theme",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }