var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("vuetable", {
        ref: "table",
        attrs: {
          "reactive-api-url": false,
          "api-mode": false,
          "data-path": "data",
          "pagination-path": "",
          "load-on-start": false,
          css: _vm.css.table,
          fields: _vm.fields,
        },
        on: {
          "vuetable:pagination-data": _vm.onPaginationData,
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function (_ref) {
              var rowData = _ref.rowData
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "300px",
                      overflow: "hidden",
                      "text-overflow": "ellipsis",
                      "white-space": "nowrap",
                    },
                    attrs: {
                      title: rowData.title,
                    },
                  },
                  [
                    _c("div", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: rowData.file,
                          },
                        },
                        [_vm._v(_vm._s(rowData.title))]
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "type",
            fn: function (_ref2) {
              var rowData = _ref2.rowData
              return [_vm._v(_vm._s(_vm.$t(_vm.getMaterialType(rowData.type))))]
            },
          },
          {
            key: "MaterialCategory",
            fn: function (_ref3) {
              var rowData = _ref3.rowData
              return [
                _vm._l(rowData.learning_wants, function (want, index) {
                  return [_c("div", [_vm._v(_vm._s(want.content.title))])]
                }),
              ]
            },
          },
          {
            key: "MaterialTheme",
            fn: function (_ref4) {
              var rowData = _ref4.rowData
              return [
                _vm._l(rowData.interested_topics, function (topic, index) {
                  return [_c("div", [_vm._v(_vm._s(topic.content.title))])]
                }),
              ]
            },
          },
          {
            key: "MaterialLevel",
            fn: function (_ref5) {
              var rowData = _ref5.rowData
              return [_c("div", [_vm._v(_vm._s(rowData.level))])]
            },
          },
          {
            key: "Operations",
            fn: function (_ref6) {
              var rowData = _ref6.rowData
              return [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal.edit_material_modal",
                        modifiers: {
                          edit_material_modal: true,
                        },
                      },
                    ],
                    staticClass: "btn btn-primary",
                    on: {
                      click: _vm.editMaterial,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("edit")))]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center",
        },
        [
          _c("vuetable-pagination-info", {
            ref: "paginationInfo",
            attrs: {
              "info-template": _vm.$t("pagination_info"),
              "no-data-template": _vm.$t("no_relevant_data"),
            },
          }),
          _c("bootstrap-pagination", {
            ref: "pagination",
            attrs: {
              css: _vm.css.pagination,
            },
            on: {
              "vuetable-pagination:change-page": _vm.onChangePage,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }